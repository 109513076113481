// AuthContext.js
import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  // Rehydrate authentication from localStorage on mount
  useEffect(() => {
    const storedAuth = localStorage.getItem("authData");
    if (storedAuth) {
      try {
        const authData = JSON.parse(storedAuth);
        if (authData && authData.token && authData.expiration) {
          const expirationDate = new Date(authData.expiration);
          if (expirationDate > new Date()) {
            setAuthenticated(true);
          } else {
            localStorage.removeItem("authData");
          }
        }
      } catch (e) {
        console.error("Error parsing authData from localStorage:", e);
        localStorage.removeItem("authData");
      }
    }
    setLoading(false);
  }, []);

  const login = () => {
    const expiration = new Date();
    expiration.setDate(expiration.getDate() + 7); // valid for one week
    const authData = {
      token: "authtoken",
      expiration: expiration.toISOString(),
    };
    localStorage.setItem("authData", JSON.stringify(authData));
    setAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem("authData");
    setAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ authenticated, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
