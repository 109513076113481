// Menu.js
import React, { useContext, useState, useEffect } from "react";
import Header from "./Header";
import { AuthContext } from "./AuthContext";
import "./Menu.css";

const Menu = () => {
  const { authenticated } = useContext(AuthContext);
  const [thought, setThought] = useState("");
  const [thoughts, setThoughts] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  // Fetch existing thoughts when component mounts
  useEffect(() => {
    const fetchThoughts = async () => {
      try {
        const response = await fetch("/api/thoughts");
        if (!response.ok) {
          throw new Error("Failed to fetch thoughts");
        }
        const data = await response.json();
        setThoughts(data);
      } catch (err) {
        console.error("Error fetching thoughts:", err);
        setError("Failed to load thoughts. Please try again later.");
      }
    };

    fetchThoughts();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!thought.trim()) {
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      const response = await fetch("/api/thoughts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content: thought }),
      });

      if (!response.ok) {
        throw new Error("Failed to submit thought");
      }

      const newThought = await response.json();
      setThoughts([newThought, ...thoughts]);
      setThought("");
    } catch (err) {
      console.error("Error submitting thought:", err);
      setError("Failed to submit your thought. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Header showLogout={authenticated} showPersonalDetails={false} />
      <div className="menu-container">
        <div className="menu-content">
          <h2>Share Your Thoughts</h2>

          {error && <div className="error-message">{error}</div>}

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="thought-input" className="label">
                Enter your thought:
              </label>
              <textarea
                id="thought-input"
                value={thought}
                onChange={(e) => setThought(e.target.value)}
                className="thought-input"
                placeholder="What's on your mind?"
                disabled={isSubmitting}
              />
            </div>

            <button
              type="submit"
              disabled={isSubmitting || !thought.trim()}
              className="submit-button"
            >
              {isSubmitting ? "Submitting..." : "Submit Thought"}
            </button>
          </form>

          {thoughts.length > 0 && (
            <div className="thoughts-group">
              <h3>Recent Thoughts</h3>
              <div>
                {thoughts.map((t) => (
                  <div key={t.id} className="thought-item">
                    <p className="thought-text">{t.content}</p>
                    <small className="thought-timestamp">
                      {new Date(t.created_at).toLocaleString()}
                    </small>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Menu;
