// Header.js
import React, { useContext } from "react";
import { AuthContext } from "./AuthContext";
import { useNavigate, Link } from "react-router-dom";
import "./Header.css";
import profilePic from "./profile_pic.jpg"; // import your image file

const Header = ({
  myname,
  myemail,
  myphone,
  occupation,
  showLogout = false,
  showPersonalDetails = true,
}) => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <header className="header">
      {showPersonalDetails ? (
        <>
          <img src={profilePic} alt="Profile" className="profile-pic" />
          <div className="header-text">
            <h1>{myname}</h1>
            <h2>{occupation}</h2>
            <p>Manteca, CA</p>
            <p>
              <a href={`mailto:${myemail}`} style={{ color: "#f0f0f0" }}>
                {myemail}
              </a>
            </p>
            <p>{myphone} [Pacific]</p>
            <p>
              With 8+ years of experience, I write pragmatic code that solves
              real-world problems.
            </p>
            <p>I've turned my passion for coding into a thriving career,</p>
            <p>
              always keen to write new chapters in my life and explore new tech
              territories!
            </p>
            <div className="header-links">
              <Link
                to="/prompter"
                style={{ color: "#f0f0f0", marginRight: "15px" }}
              >
                Prompt Generator
              </Link>
            </div>
          </div>
          {showLogout && (
            <button onClick={handleLogout} style={{ marginLeft: "20px" }}>
              Logout
            </button>
          )}
        </>
      ) : (
        // Minimal header for private pages: no profile picture or personal details.
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          {showLogout && (
            <button onClick={handleLogout} style={{ marginRight: "20px" }}>
              Logout
            </button>
          )}
        </div>
      )}
    </header>
  );
};

export default Header;
